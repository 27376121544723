/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Nav, NavbarBrand, NavbarToggler, Collapse, NavItem } from "reactstrap";

import * as Icon from "react-feather";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

import SectionPrivacyPolice from "../component/SectionPrivacyPolice";
import SectionProject from "../component/SectionProject";
import SectionTestimonial from "../component/SectionTestimonial";
import SectionContactUs from "../component/SectionContactUs";
import Footer from "../component/Footer";

import Logodark from "../assets/images/logo-dark.png";
import Logolight from "../assets/images/logo-light.png";
import ENIcon from "../assets/images/icons/en.png";
import IDIcon from "../assets/images/icons/id.png";

const LanguageSelector = ({ currentLanguage, changeLanguage }) => {
  const [isLanguageToggled, setLanguageToggled] = useState(false);

  const toggleLanguage = () => setLanguageToggled(!isLanguageToggled);

  const setLanguage = (language) => {
    setLanguageToggled(false);
    changeLanguage(language);
  };

  return (
    <div onClick={toggleLanguage}>
      <span className="font-lg color-grey-900 arrow-down d-inline-flex align-items-center">
        <img
          src={currentLanguage === "id" ? IDIcon : ENIcon}
          alt={currentLanguage}
        />
        {currentLanguage.toUpperCase()}
      </span>
      <div
        className={isLanguageToggled ? "dropdown-menu show" : "dropdown-menu"}
      >
        <ul className="list-unstyled m-0 px-2">
          <li>
            <Link
              activeClass="active"
              className="text-muted"
              onClick={() => setLanguage("id")}
            >
              <img src={IDIcon} alt="ID" /> ID
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              className="text-muted"
              onClick={() => setLanguage("en")}
            >
              <img src={ENIcon} alt="EN" /> EN
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default function App() {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setMenu] = useState(true);
  const [arrow, setArrow] = useState(false);

  const [language, setLanguage] = useState("en");

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const handleScroll = () => {
    if (
      document.body.scrollTop >= 500 ||
      document.documentElement.scrollTop >= 500
    ) {
      setArrow(true);
    } else {
      setArrow(false);
    }
  };

  const windowScroll = () => {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  };

  const toggleMenu = () => {
    setMenu(!isMenuOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", windowScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", windowScroll);
    };
  }, []);

  return (
    <>
      <div>
        <nav
          id="navbar"
          className="navbar navbar-expand-lg nav-light fixed-top sticky"
        >
          <div className="container">
            <NavbarBrand className="navbar-brand" href="/">
              <span className="logo-light-mode">
                <img src={Logolight} className="l-light" alt="" height={38} />
                <img src={Logodark} className="l-dark" alt="" height={38} />
              </span>
              <img src={Logolight} className="logo-dark-mode" alt="" />
            </NavbarBrand>
            <NavbarToggler onClick={toggleMenu}>
              <Icon.Menu />
            </NavbarToggler>

            <Collapse
              className={`navbar-collapse ${
                isMenuOpen === true ? "hidden" : "show"
              }`}
              id="navbarSupportedContent"
            >
              <Nav
                className="navbar-nav ms-auto mb-2 mb-lg-0"
                id="navbar-navlist"
              >
                <NavItem>
                  <Link
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    {t("navbar.home")}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="about-us"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    {t("navbar.about_us")}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="solution"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    {t("navbar.solutions")}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="project"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    {t("navbar.performance")}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="testimonial"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    {t("navbar.testimonials")}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    {t("navbar.contact_us")}
                  </Link>
                </NavItem>
                <NavItem className="d-flex align-items-center">
                  <Link activeClass="active" className="nav-link">
                    <LanguageSelector
                      currentLanguage={language}
                      changeLanguage={changeLanguage}
                    />
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </nav>

        <SectionPrivacyPolice />
        <SectionProject />
        <SectionTestimonial />
        <SectionContactUs />

        <Footer />
        <Link
          to="home"
          style={{ display: arrow === true ? "block" : "none" }}
          id="back-to-top"
          className="back-to-top rounded-pill fs-5"
        >
          <Icon.ArrowUp className="fea icon-sm icons align-middle" />
        </Link>
      </div>
    </>
  );
}
