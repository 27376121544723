/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Container } from "reactstrap";
import CTA from "../assets/images/bg/nadi-cta.jpg";
import { useTranslation } from "react-i18next";

export default function SectionDeleteAccount() {
  const { t, i18n } = useTranslation();
  const deleteAccount = () => [
    {
      id: "section1",
      title: t("delete_account.section_1.title"),
      description: t("delete_account.section_1.description"),
    },
    {
      id: "section2",
      title: t("delete_account.section_2.title"),
      description: t("delete_account.section_2.description"),
    },
    {
      id: "section3",
      title: t("delete_account.section_3.title"),
      description: t("delete_account.section_3.description"),
    },
    {
      id: "section4",
      title: t("delete_account.section_4.title"),
      description: t("delete_account.section_4.description"),
    },
  ];

  const deleteAccountData = useMemo(() => deleteAccount(), [i18n.language]);

  return (
    <>
      <section className="section" style={{ background: `url(${CTA}) center` }}>
        <div className="bg-overlay"></div>
        <Container>
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center">
                <h4 className="title text-white">
                  {t("delete_account.title")}
                </h4>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="section bg-light" id="solution">
        <Container>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="me-lg-5">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {deleteAccountData.map((item) => (
                        <div
                          key={item.id}
                          className="section-title text-md-start mb-4 pb-2"
                        >
                          <h4 className="title mb-3">{item.title}</h4>
                          <p className="text-muted mx-auto mb-0">
                            {item.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
